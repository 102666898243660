<template>
    <div>
        <el-dialog
                width="30%"
                :visible.sync="showDialog"
                :title="title"
                :modal-append-to-body="true"
                :append-to-body="true"
                style="text-align:left;"
                @close="closeDialog"
                :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate">
                    <el-form-item label="商品" prop="goodsId" label-width="80px">
                        <template>
                            <el-select v-model="formModel.goodsId" filterable placeholder="请选择" style="width:300px">
                                <el-option
                                        v-for="result in goodsList"
                                        :key="result.id"
                                        :label="result.name"
                                        :value="result.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                    <el-form-item label="费用（元）" prop="cost" label-width="80px">
                        <el-input v-model="formModel.cost" placeholder="请输入费用" style="width:50%"/>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import goodsInfoApi from "@/api/base/goodsInfo";
    import routeApi from "@/api/bus/route";
    export default {
        props: ["businessKey", "title"],
        data(){
            return{
                ruleValidate: {
                    goodsId: [{ required: true, message: "商品不能为空", trigger: "blur" }],
                    cost: [{ required: true, message: "费用不能为空", trigger: "blur" }]
                },
                showDialog: true,
                loading: false,
                formModel: {
                    id: "",
                    goodsId: "",
                    cost:"",
                    active:true
                },
                submitting: false,
                goodsList: []
            }
        },
        created() {
            var self = this;

            goodsInfoApi.typeList({type:"1"}).then(response => {
                var jsonData = response.data;
                this.goodsList = jsonData.data;
            });
        },
        methods:{
            closeDialog() {
                this.$emit("close", false);
            },
            handleSubmit() {
                var self = this;

                this.$refs["form"].validate(valid => {
                    if (valid) {
                        self.loading = true;

                        var formData = new FormData();
                        formData.append("id",self.businessKey);
                        formData.append("goodsId",self.formModel.goodsId);
                        formData.append("cost",self.formModel.cost);



                        (function() {

                                return routeApi.setCost(formData);

                        })().then(function(response) {
                            self.loading = false;
                            var jsonData = response.data;

                            if (jsonData.result) {
                                self.$message({
                                    message: "保存成功!",
                                    type: "success"
                                });
                                self.$emit("close", {
                                    result: true,
                                    data: jsonData.data
                                });
                            } else {
                                self.$message({
                                    message: jsonData.message + "",
                                    type: "warning"
                                });

                                self.$emit("close", {
                                    result: false
                                });
                            }
                        });
                    }
                });

            },
        },
        async mounted() {
            console.log("mounted");
            self.loading = true;




        }
    }

</script>

<style scoped>

</style>
<template>
  <div>
    <el-dialog
      width="80%"
      :visible.sync="showDialog"
      :title="title"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div class="user-panel" v-loading="loading">
        <el-form ref="form" :model="formModel" :rules="ruleValidate" :inline="true">
          <el-form-item label="线路名称" prop="name">
            <el-input v-model="formModel.name" placeholder="请输入线路名称" style="width:250px"></el-input>
          </el-form-item>
          <el-form-item label="运营单位" prop="companyId"> 
            <el-select v-model="formModel.companyId" filterable placeholder="请选择" style="width:250px">
              <el-option
                v-for="result in companyList"
                :key="result.id"
                :label="result.name"
                :value="result.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联商品" prop="goodsId" label-width="80px">
                        <template>
                            <el-select v-model="formModel.goodsId" filterable placeholder="请选择" style="width:250px">
                                <el-option
                                        v-for="result in goodsList"
                                        :key="result.id"
                                        :label="result.name"
                                        :value="result.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
          <h5>车辆站点信息</h5>
          <el-table :data="formModel.stationList">
            <el-table-column type="index" label="序号" width="55"></el-table-column>
            <el-table-column prop="name" label="站点名称">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'stationList.' + scope.$index + '.name'"
                  :rules="ruleValidate['name']"
                >
                  <el-input placeholder="站点名称" style="width:200px" v-model="scope.row.name"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="classify" label="站点类型">
              <template slot-scope="{row}">
                  <el-form-item>
                    <el-select v-model="row.classify" placeholder="请选择" style="width:150px;">
                        <el-option
                        v-for="result in stationClassify"
                        :key="result.id"
                        :label="result.name"
                        :value="result.value"
                        ></el-option>
                    </el-select>
                  </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="location" label="站点定位">
              <template slot-scope="{row}">
                <el-form-item>
                  <el-input placeholder="经纬度" style="width:200px" v-model="row.location"></el-input>
                  <i class="el-icon-map-location my-font" @click="handleLocation(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="{row}">
                <el-form-item>
                  <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                  <i class="el-icon-top my-font" @click="handleUp(row)"></i>
                  <i class="el-icon-bottom my-font" @click="handleDown(row)"></i>
                  <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>
            <div slot="empty">
              <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
            </div>
          </el-table>
        </el-form>
        <div class="el-table__empty-block" v-if="formModel.stationList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showMapDialog"
      title="地图选点"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align:left;"
      :close-on-click-modal="false"
    >
      <el-amap
        ref="stationMap"
        vid="stationMap"
        :amap-manager="amapManager"
        :center="pointPosition"
        :zoom="zoom"
        :events="events"
        class="amap-demo"
      >
        <el-amap-marker vid="amapDemo" :position="pointPosition"></el-amap-marker>
      </el-amap>
      <span slot="footer" class="dialog-footer">
        <div style="position:absolute;left:20px;bottom:20px;">
          <el-input placeholder="当前经纬度" v-model="selectedRow.location" 
          style="width:400px;">
            <template slot="prepend">经纬度</template>
            <el-button slot="append" icon="el-icon-search" @click="handleRegeo">定位</el-button>
          </el-input>
        </div>
        <el-button @click="showMapDialog=false;">取 消</el-button>
        <el-button type="primary" @click="handleSelectPoint()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import routeApi from "@/api/bus/route";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import goodsInfoApi from "@/api/base/goodsInfo";

import AMap from "vue-amap";
let amapManager = new AMap.AMapManager();

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [{ required: true, message: "路线名称不能为空", trigger: "blur" }],
        goodsId: [{ required: true, message: "对应商品不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      formModel: {
        id: "1",
        name: "",
        companyId: "",
        goodsId: "",
        stationList: [],
        removeStationList: []
      },
      submitting: false,
      stationClassify: [],
      amapManager,
      zoom: 12,
      events: {
        click: e => {
          var pt = e.lnglat; //点击选择新地址为中心点
          console.log(pt);

          this.pointPosition = [pt.lng, pt.lat];
          this.selectedRow.location = pt.lng + "," + pt.lat;
        }
      },
      pointPosition: [112.240222, 30.337053],
      showMapDialog: false,
      selectedRow: {},
      companyList: [],
      goodsList: []
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("catalogName", "站点类型");

    dataDictionaryApi.findByCatalogName(formData).then(response => {
      var jsonData = response.data;
      this.stationClassify = jsonData.data;
    });

    companyInfoApi.treeList({type:"1"}).then(response => {
      var jsonData = response.data;
      this.companyList = jsonData.data;
    });

    goodsInfoApi.typeList({type:"1"}).then(response => {
        var jsonData = response.data;
        this.goodsList = jsonData.data;
    });
  },
  computed: {
    // stationSortList() {
    //   return this.formModel.stationList.sort((a,b) => {
    //     return a.sortNo - b.sortNo
    //   });
    // }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {          
          self.loading = true;

          var stationList = this.formModel.stationList;

          for(var i=0;i<stationList.length;i++){
            stationList[i].sortNo = i+1;
          }

          (function() {
            var id = self.formModel.id;
            if (id == null || id.length == 0) {
              return routeApi.add(self.formModel);
            } else {
              return routeApi.update(self.formModel);
            }
          })().then(function(response) {
            self.loading = false;
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });
              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result: false
              });
            }
          });
        }
      });
    },
    handleAdd() {
      this.formModel.stationList.push({
        sortNo: this.formModel.stationList.length + 1,
        name: "",
        routeId: this.businessKey,
        classify: "",
        location: "",
        delFlag: 0
      });
    },
    handleLocation(row) {
      this.selectedRow = row;
      this.showMapDialog = true;

      if (row.location != null && row.location.length > 0) {
        var arr = row.location.split(",");

        this.pointPosition = arr;
      }
    },
    handleRegeo(){
      var row = this.selectedRow;

      if (row.location != null && row.location.length > 0) {
        var arr = row.location.split(",");
        this.pointPosition = arr;
      }
    },
    handleSelectPoint() {
      this.selectedRow.location =
        this.pointPosition[0] + "," + this.pointPosition[1];
      this.showMapDialog = false;
    },
    handleDelete(row) {
      var stationList = this.formModel.stationList;

      stationList.splice(stationList.indexOf(row), 1);

      this.formModel.removeStationList.push(row);
    },
    handleClassifyList() {
      this.stationClassifyList = routeApi.stationClassifyList();
    },
    handleUp(row) {
      var stationList = this.formModel.stationList;
      var index = stationList.indexOf(row);

      if(index>0){
        stationList.splice(index, 1);
        stationList.splice(index-1,0,row);
      }
    },
    handleDown(row) {
      var stationList = this.formModel.stationList;
      var index = stationList.indexOf(row);

      if(index<stationList.length-1){
        stationList.splice(index, 1);
        stationList.splice(index+1,0,row);
      }
    },
    handleInsert(row) {
      var stationList = this.formModel.stationList;
      var index = stationList.indexOf(row);

      if(index>=0){
        stationList.splice(index+1,0,{
          sortNo: index + 2,
          name: "",
          routeId: this.businessKey,
          classify: "",
          location: "",
          delFlag: 0
        });
      }
    }
  },
  async mounted() {
    console.log("mounted");

    var self = this;

    self.loading = true;

    (function() {
      if (self.businessKey.length == 0) {
        return routeApi.create();
      } else {
        return routeApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.amap-demo {
  width: 100%;
  height: 400px;
}

.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
</style>
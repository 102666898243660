<template>
    <div class="route-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">路线管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="所属单位" prop="routeName">
                <el-select v-model="queryModel.companyId" 
                filterable clearable size="mini"
                placeholder="请选择">
                    <el-option
                        v-for="result in companyList"
                        :key="result.id"
                        :label="result.name"
                        :value="result.id">
                    </el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="线路名称" prop="routeName">
                <el-input v-model="queryModel.routeName" size="mini"/>
            </el-form-item>
            <!-- <el-form-item label="费用" prop="cost">
                <el-input v-model="queryModel.cost" size="mini"/>
            </el-form-item> -->
            <el-form-item>
                <el-button
                        type="primary"
                        size="mini"
                        icon="ios-search"
                        @click="changePage(1)"
                        :loading="loading"
                >查询
                </el-button>&nbsp;
                <el-button
                        type="info"
                        size="mini"
                        style="margin-left: 8px"
                        @click="handleReset('queryForm')"
                >重置
                </el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
            <el-button
                    type="primary"
                    size="small"
                    plain
                    icon="el-icon-circle-plus"
                    @click="handleAdd(null)"
            >新增
            </el-button>
            <el-button
                    type="primary"
                    size="small"
                    plain
                    icon="el-icon-remove"
                    :disabled="multipleSelection.length==0"
                    @click="handleBatchDelete"
            >删除选中项
            </el-button>
        </el-row>
        <el-table
                ref="formTable"
                :data="tableData"
                v-loading="loading"
                element-loading-text="拼命加载中"
                stripe
                @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="companyName" label="运营单位" fixed="left"></el-table-column>
            <el-table-column prop="name" label="线路名称" fixed="left"></el-table-column>
            <el-table-column prop="goodsName" label="商品名称" fixed="left"></el-table-column>
            <!-- <el-table-column prop="cost" label="费用（元）" fixed="left"></el-table-column> -->
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                    <el-button size="mini" type="success" @click="handleEditPath(row)">路线绘制</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                    <!-- <el-button size="mini" type="success" @click="handleEditCost(row)">费用设置</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                    :current-page.sync="pageIndex"
                    :total="totalElements"
                    :page-sizes="pageSizeList"
                    @current-change="changePage"
                    @size-change="pageSizeChange"
                    layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </div>
        <route-detail
                v-if="showModal"
                :businessKey="businessKey"
                :title="modalTitle"
                @close="onDetailModalClose"
        ></route-detail>
        <route-cost
                v-if="showModal2"
                :businessKey="businessKey"
                :title="modalTitle"
                @close="onDetailModalClose2"
        ></route-cost>

        <el-dialog
                width="80%"
                :visible.sync="showMapDialog"
                title="路线绘制"
                :modal-append-to-body="true"
                :append-to-body="true"
                style="text-align:left;"
                :close-on-click-modal="false"
                v-loading="mapLoading"
        >
            <el-amap
                    ref="roadlineMap"
                    vid="roadlineMap"
                    :plugin="plugin"
                    :center="centerPoint"
                    :zoom="15"
                    style="width:100%;height:400px;"
            >
                <!--站点-->
                <el-amap-marker :zIndex="1"
                                v-for="station in stationList"
                                :key="station.id"
                                :position="station.position"
                                :title="station.title"></el-amap-marker>
                <!--路线-->
                <el-amap-polyline :path="mapPath"
                                  :zIndex="2"
                                  :isOutline="true"
                                  :outlineColor="'#ffeeff'"
                                  :borderWeight="3"
                                  :strokeColor="'#3366FF'"
                                  :strokeOpacity="1"
                                  :strokeWeight="6"
                                  :strokeStyle="'solid'"
                                  :strokeDasharray="[10, 5]"
                                  :lineJoin="'round'"
                                  :lineCap="'round'"
                                  :editable="true"
                ></el-amap-polyline>
            </el-amap>
            <span slot="footer" class="dialog-footer">
            <el-button @click="handleConnectStation()">站点连线</el-button>
            <el-button @click="handleMapPathConfirm()">确定</el-button>
            <el-button @click="showMapDialog=false;">关闭</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
    import routeApi from "@/api/bus/route";
    import routeDetail from "./route-detail";
    import routeCost from "./route-cost";
    import companyInfoApi from "@/api/base/companyInfo";

    export default {
        name: "busRouteList",
        data() {
            return {
                queryModel: {
                    routeName: "",
                    companyId: ""
                },
                loading: false,
                tableData: [],
                multipleSelection: [],
                pageIndex: 1,
                pageSize: 10,
                totalElements: 0,
                pageSizeList: [10, 20, 30],
                plugin: [{
                    pName: 'ToolBar',
                    events: {
                        init(instance) {
                            console.log(instance);
                        }
                    }
                }],
                showModal: false,
                showModal2: false,
                mapLoading: false,
                showMapDialog: false,
                selectRoute: null,
                centerPoint: [112.276585, 30.306401],
                stationList: [
                    {id: 1, position: [112.276585, 30.306401], content: ''},
                    {id: 2, position: [112.275276, 30.306827], content: ''}
                ],
                mapPath: [[112.276585, 30.306401], [112.275276, 30.306827]],
                businessKey: "",
                modalTitle: "",
                companyList: []
            };
        },
        methods: {
            handleAdd(record) {
                this.modalTitle = "新增";
                this.operation = "add";
                this.businessKey = "";

                if (record != null) {
                    this.selectedRecord = record;
                } else {
                    this.selectedRecord = {};
                }

                this.showModal = true;
            },
            handleBatchDelete() {
                var self = this;

                var idList = this.multipleSelection.map(record => {
                    return record.id;
                });

                this.$confirm("是否确认删除选中项？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    routeApi.batchRemove(idList).then(function (response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.changePage(self.pageIndex);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            changePage(pageIndex) {
                this.loading = true;
                var formData = new FormData();

                formData.append("pageIndex", this.pageIndex);
                formData.append("pageSize", this.pageSize);

                if(this.queryModel.routeName!=null){
                    formData.append("routeName", this.queryModel.routeName);
                }

                if(this.queryModel.companyId!=null){
                    formData.append("companyId", this.queryModel.companyId);
                }
                
                routeApi.pageList(formData).then(response => {
                    this.loading = false;
                    var jsonData = response.data;

                    console.log(jsonData);

                    this.tableData = jsonData.data.data;
                    this.totalElements = jsonData.data.recordsTotal;

                    this.pageIndex = pageIndex;
                });
            },
            pageSizeChange(pageSize) {
                this.pageSize = pageSize;
            },
            handleEdit(record) {
                this.modalTitle = "编辑";
                this.operation = "edit";
                this.businessKey = record.id;
                this.selectedRecord = record;
                this.showModal = true;
            },
            loopDelete(list, id) {
                var rs = false;

                for (var i = 0; i < list.length; i++) {
                    if (list[i].id == id) {
                        list.splice(i, 1);
                        rs = true;
                        break;
                    }

                    if (list[i].children != null) {
                        rs = this.loopDelete(list[i].children, id);

                        if (rs) {
                            break;
                        }
                    }
                }

                return rs;
            },
            handleDelete(record) {
                var self = this;

                self
                    .$confirm("是否确认删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    })
                    .then(() => {
                        routeApi.remove(record.id).then(function (response) {
                            var jsonData = response.data;

                            if (jsonData.result) {
                                var rs = self.loopDelete(self.tableData, record.id);

                                self.$message({
                                    type: "success",
                                    message: "删除成功!"
                                });
                            }
                        });
                    });
            },
            handleEditCost(record) {
                this.modalTitle = "费用设置";
                this.operation = "edit";
                this.businessKey = record.id;
                this.selectedRecord = record;
                this.showModal2 = true;

            },
            handleReset(name) {
                this.$refs[name].resetFields();
            },

            onDetailModalClose2(retObj) {
                //保存成功后回调
                this.showModal2 = false;
                this.changePage(this.pageIndex);
            },
            onDetailModalClose(retObj) {
                //保存成功后回调
                this.showModal = false;

                if (retObj.result) {
                    this.changePage(this.pageIndex);                    
                }
                //     var retData = retObj.data;

                //     if (this.operation == "edit") {
                //         this.selectedRecord.name = retData.name;

                //         if (this.selectedRecord.parentId != retData.parentId) {
                //             this.loopDelete(this.tableData, this.selectedRecord.id);

                //             var parent = this.loopFind(this.tableData, retData.parentId);

                //             if (parent != null) {
                //                 if (parent.children == null) {
                //                     parent.children = [];
                //                 }

                //                 parent.children.push(retData);
                //             }
                //         }
                //     } else if (this.operation == "add") {
                //         if (this.selectedRecord.id == null) {
                //             console.log("tableData.push");
                //             this.tableData.push(retData);
                //         } else {
                //             if (this.selectedRecord.children == null) {
                //                 this.selectedRecord.children = [];
                //             }

                //             this.selectedRecord.hasChildren = true;
                //             this.selectedRecord.children.push(retData);
                //         }
                //     }
                // }
            },
            handleEditPath(row) {
                //读取当前路线的站点
                this.loading = true;

                routeApi.edit(row.id).then(response => {
                    this.loading = false;

                    var jsonData = response.data;
                    var model = jsonData.data;

                    var _stationList = model.stationList;

                    if (model.pathList != null) {
                        this.mapPath = model.pathList.map((item) => {
                            return item.split(",");
                        });

                        this.centerPoint = this.mapPath[0];
                    } else {
                        this.mapPath = [];
                    }

                    if (_stationList.length <= 1) {
                        this.$message.warning("请先至少设置2个站点!");
                    } else {
                        this.selectRoute = row;
                        this.showMapDialog = true;

                        this.stationList = [];

                        var initMapPath = this.mapPath.length == 0;

                        _stationList.forEach((item) => {
                            if (item.location != null && item.location.length > 0) {
                                var station = {
                                    id: item.id,
                                    title: item.name,
                                    position: item.location.split(",")
                                }

                                this.stationList.push(station);

                                if (initMapPath) {
                                    this.mapPath.push(station.position);
                                }
                            }
                        });

                        if(this.mapPath.length>0){
                            this.centerPoint = this.mapPath[0];
                        }
                    }
                });
            },
            handleMapPathConfirm() {
                this.mapLoading = true;

                var pathList = this.mapPath.map((item) => {
                    return item.lng + "," + item.lat;
                });

                routeApi.updateMapPath({
                    id: this.selectRoute.id,
                    pathList: pathList
                }).then(response => {
                    this.mapLoading = false;

                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.showMapDialog = false;
                        this.$message.success("保存成功!");
                    } else {
                        this.$message.warning("系统错误!" + jsonData.message);
                    }
                });
            },
            handleConnectStation() {
                this.$confirm("是否确认根据站点重新绘制路线?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.mapPath = [];
                        this.stationList.forEach((item) => {
                            this.mapPath.push(item.position);
                        });
                    });
            }
        },
        components: {
            "route-detail": routeDetail,
            "route-cost": routeCost
        },
        mounted() {
            this.changePage(1);
        },        
        created() {
            companyInfoApi.treeList({type:"1"}).then(response => {
                var jsonData = response.data;
                this.companyList = jsonData.data;
            });
        }
    };
</script>
<style lang="scss" scoped>
    .el-breadcrumb {
        margin: 10px;
        line-height: 20px;
    }

    .el-divider {
        margin: 5px 0;
    }

    .route-list {
        text-align: left;
    }

    .demo-form-inline {
        margin-left: 10px;
        text-align: left;
    }
</style>